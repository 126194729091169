const breakpoints = {
  phone: '375px',
  tablet: '768px',
  desktop: '1024px',
  largeDesktop: '1440px',
};

export const devices = {
  phone: `(min-width: ${breakpoints.phone})`,
  tablet: `(min-width: ${breakpoints.tablet})`,
  desktop: `(min-width: ${breakpoints.desktop})`,
  largeDesktop: `(min-width: ${breakpoints.largeDesktop})`,
};
