'use client';
import styled from 'styled-components';

import { devices } from '../styles/breakpoints';

export const Container = styled.section`
  position: relative;
  max-width: 1128px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @media only screen and ${devices.largeDesktop} {
    padding: 0;
  }
`;
